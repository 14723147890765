<template>
  <AppLayout v-slot="{}">
    <Breadcrumbs />
    <b-container class="account">
      <b-tabs
        pills
        card
        vertical
        v-model="tabIndex"
        :nav-wrapper-class="{
          'cst-tab': true,
          'col-lg-3': true,
          sidebarhide: !isTabShow && isMobileScreen,
        }"
      >
        <b-tab @click="hideTabs" :title="$t('my_account')"
          ><AccountMyAccount @showTabs="showTabs" :isTabShow="isTabShow" />
        </b-tab>
        <!-- <b-tab @click="hideTabs" :title="$t('my_orders')"
          ><b-card-text
            ><AccountOrders
              @showTabs="showTabs"
              :isTabShow="isTabShow" /></b-card-text
        ></b-tab> -->
        <!-- <b-tab @click="hideTabs" :title="$t('my_wishlist')"
          ><b-card-text
            ><AccountWishlist
              @showTabs="showTabs"
              :isTabShow="isTabShow" /></b-card-text
        ></b-tab> -->
        <b-tab @click="hideTabs" :title="$t('address_book')">
          <AccountAddressBook @showTabs="showTabs" :isTabShow="isTabShow" />
        </b-tab>
        <b-tab @click="hideTabs" :title="$t('account_information')">
          <AccountInformation @showTabs="showTabs" :isTabShow="isTabShow" />
        </b-tab>
        <b-tab @click="hideTabs" :title="$t('change_email')">
          <AccountEmail @showTabs="showTabs" :isTabShow="isTabShow" />
        </b-tab>
        <b-tab @click="hideTabs" :title="$t('change_password')">
          <AccountPassword @showTabs="showTabs" :isTabShow="isTabShow" />
        </b-tab>
        <!-- <b-tab @click="hideTabs" :title="$t('my_product_reviews')">
          <b-card-text><AccountReviews /></b-card-text>
        </b-tab> -->
        <b-tab @click="hideTabs" :title="$t('newsletter_subscriptions')">
          <NewsletterSubscription @showTabs="showTabs" :isTabShow="isTabShow" />
        </b-tab>
      </b-tabs>
    </b-container>
  </AppLayout>
</template>

<script>
import AppLayout from "@/esf_kerkrade_vitanatura/core/components/AppLayout";
import Breadcrumbs from "@/esf_kerkrade_vitanatura/core/components/Breadcrumbs";
import AccountInformation from "@/base/core/components/account/AccountInformation";
import AccountEmail from "@/base/core/components/account/AccountEmail";
import AccountPassword from "@/base/core/components/account/AccountPassword";
import AccountMyAccount from "@/base/core/components/account/AccountMyAccount";
import AccountAddressBook from "@/esf_kerkrade_vitanatura/core/components/account/AccountAddressBook";
import NewsletterSubscription from "@/base/core/components/account/NewsletterSubscription";
// import AccountWishlist from "@/base/core/components/account/AccountWishlist";
// import AccountOrders from "@/base/core/components/account/AccountOrders";
import { isMobile } from "mobile-device-detect";

export default {
  name: "Account",
  components: {
    AppLayout,
    Breadcrumbs,
    AccountInformation,
    AccountEmail,
    AccountPassword,
    AccountAddressBook,
    AccountMyAccount,
    NewsletterSubscription,
    // AccountWishlist,
    // AccountOrders,
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters["user/getIsLoggedIn"];
    },
    tabIndex: {
      get() {
        return this.$store.getters["user/getAccountTabIndex"];
      },
      set(val) {
        this.$store.commit("user/setAccountTabIndex", val);
      },
    },
    isMobileScreen() {
      return isMobile;
    },
  },
  mounted() {
    const bcrumb = { current: this.$t("account"), routes: [] };
    this.$store.commit("breadcrumbs/set", bcrumb);
  },
  data() {
    return {
      isTabShow: true,
      thanksActive: false,
    };
  },
  methods: {
    hideTabs() {
      this.isTabShow = false;
    },
    showTabs() {
      this.isTabShow = true;
    },
    goLogout() {
      this.visible = false;
      this.$store.dispatch("user/logout");
      this.$router.push("/");
    },
    formSubmit() {
      this.thanksActive = true;
    },
    formSubmitValidate() {},
  },
};
</script>

